import "styles/pages/single-post.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";

const SingleEducation = ({ pageContext }) => {
   const seo = pageContext.seo;

   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs
            currentLocationLabel={pageContext.title}
            prevLocationLabel="Szkolenia i webinary"
            prevLocationLink="/szkolenia-i-webinary"
         />
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-xl-10">
                  <article className="post">
                     <div className="post__wrapper">
                        <img
                           src={
                              pageContext.img
                                 ? pageContext.img.node.sourceUrl
                                 : require("../assets/images/news-placeholder.jpg")
                                      .default
                           }
                           alt={pageContext.title}
                           className="post__img"
                        />
                        <div className="post__date">{pageContext.date}</div>
                     </div>
                     <SectionTitle title={pageContext.title} />
                     <div
                        className="post__content"
                        dangerouslySetInnerHTML={{
                           __html: pageContext.content,
                        }}
                     />
                  </article>
               </div>
            </div>
         </div>
      </Layout>
   );
};

export default SingleEducation;
